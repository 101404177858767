import React from "react";
import { BlogPostPreview } from "../components/BlogPostPreview/BlogPostPreview";
import { Footer } from "../components/Footer/Footer";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Grid, Root, Wrapper } from "../page-styles/markdown.styles";
import { NavigationHeader } from "../components/NavigationHeader/NavigationHeader";
import { SEO } from "../components/SEO/SEO";
import { useTranslation } from "react-i18next";

const Blogs = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const [t] = useTranslation();
    const title = t("blogs");
    const description = t("coderoneBlogs");

    const posts = edges.map((edge) => {
        const image = getImage(edge.node.frontmatter.featuredImage) as IGatsbyImageData;
        const imageAlt = edge.node.frontmatter.title;
        return (
            <BlogPostPreview key={edge.node.id} {...edge.node}>
                <GatsbyImage image={image} alt={imageAlt} style={{ borderRadius: 2, marginTop: 16, marginBottom: 16 }} />
            </BlogPostPreview>
        );
    });
    return (
        <React.Fragment>
            <SEO title={title} description={description} />
            <NavigationHeader />
            <Root>
                <Wrapper>
                    <Grid>{posts}</Grid>
                </Wrapper>
            </Root>
            <Footer />
        </React.Fragment>
    );
};
export default Blogs;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { fileAbsolutePath: { regex: "/(src/source-filesystem/markdown-pages)/" } }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        description
                        author
                        tag
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData(width: 800, height: 400, placeholder: BLURRED, transformOptions: { fit: COVER })
                            }
                        }
                    }
                }
            }
        }
    }
`;
